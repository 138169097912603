import * as React from 'react';
import { NumberField } from 'react-admin';
import { cloneDeep, get, set } from 'lodash';

export const StripePriceField = (props: any) => {
  if (!props.record) {
    return null;
  }

  const value = get(props.record, props.source);
  if (isNaN(value)) {
    return null;
  }

  const clonedRecord = set(cloneDeep(props.record), props.source, value / 100);

  return (
    <NumberField
      {...props}
      record={clonedRecord}
      options={{ style: 'currency', currency: 'USD' }}
    />
  );
};
