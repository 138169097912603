import * as React from 'react';
import { Datagrid as ReactAdminDatagrid } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  rowEven: {
    backgroundColor: '#fafafa',
  },
  rowOdd: {
    backgroundColor: '#fff',
  },
  rowCell: {
    height: '36px',
  },
});

export const Datagrid = (props: any) => {
  const classes = useStyles();
  return (
    <ReactAdminDatagrid {...props} classes={classes}>
      {props.children}
    </ReactAdminDatagrid>
  );
}
