import React, { Children } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

type FormBlockProps = BoxProps & { columnWidth?: number };

export const FormBox = ({ children, ...props }: BoxProps) => {
  return (
    <Box {...props} display="flex">
      {children}
    </Box>
  );
};

export const FormColumn = ({
  children,
  columnWidth,
  ...props
}: FormBlockProps) => {
  return (
    <Box {...props} flex={columnWidth ?? 1}>
      {children}
    </Box>
  );
};

export const FormRow = ({ children, ...props }: any) => {
  const { columnWidth, record } = props;
  return (
    <FormBox>
      {Children.map(
        children,
        (input: any, idx) =>
          input && (
            <FormColumn columnWidth={columnWidth} pl={idx > 0 ? '1em' : 0}>
              {React.cloneElement(input, { record })}
            </FormColumn>
          )
      )}
    </FormBox>
  );
};
