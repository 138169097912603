import * as React from 'react';
import {
  List,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  DateInput,
  DateTimeInput,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Filter,
  TopToolbar,
  SaveButton,
  Toolbar,
  required,
  linkToRecord,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';
import UserIcon from '@material-ui/icons/Person';

import { DateField } from 'components/DateField';
import { RoleInput } from 'components/RoleInput';
import { ChangeEmailButton } from 'components/ChangeEmailButton';
import { ResetPasswordButton } from 'components/ResetPasswordButton';
import { StartZohoSyncButton } from 'components/StartZohoSyncButton';
import { Datagrid } from 'components/Datagrid';
import { FormRow } from 'components/FormLayout';

import { User } from '../types';
import { RoleField } from 'components/RoleField';
import { Link } from 'react-router-dom';
import { isNil, omitBy } from 'lodash';
import {
  createCsvExporter,
  exportDate,
  exportDateTime,
} from 'helpers/exporter';

const rowToExport = (user: User) => ({
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  organizationName: user.account?.organizationName,
  lastLoginRefresh: exportDateTime(user.lastLoginRefresh),
  trialEnd: exportDate(user.account?.trialEnd),
  created: exportDate(user.created),
  jobRole: user.jobRole,
});

const userExporter = createCsvExporter('users', rowToExport);

const choices = [
  { id: 'Retailer', name: 'Retailer' },
  { id: 'Broker', name: 'Broker' },
  { id: 'Owner/Developer', name: 'Owner/Developer' },
  { id: 'Other', name: 'Other' },
];

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="email" />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="referrer" />
    <TextInput source="account.organizationName" label="Organization" />
    <DateInput source="lastLoginRefresh_lte" label="Active before" />
    <DateInput source="lastLoginRefresh_gte" label="Active after" />
    <DateInput source="account.trialEnd_lte" label="Trial End before" />
    <DateInput source="account.trialEnd_gte" label="Trial End after" />
    <DateInput source="account.sensormaticLicenseEnd_lte" label="Sensormatic License before" />
    <DateInput source="account.sensormaticLicenseEnd_gte" label="Sensormatic License after" />
    <DateInput source="created_lte" label="Created before" />
    <DateInput source="created_gte" label="Created after" />
  </Filter>
);

export const UserList = (props: any) => (
  <React.Fragment>
    <List
      {...props}
      filters={<UserFilter />}
      bulkActionButtons={false}
      exporter={userExporter}
    >
      <Datagrid rowClick="edit">
        <TextField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <ReferenceField
          source="accountId"
          label="Organization"
          reference="accounts"
          sortable={false}
        >
          <TextField source="organizationName" />
        </ReferenceField>
        <DateField source="lastLoginRefresh" label="Last Active" showTime />
        <ReferenceField
          source="accountId"
          label="Trial End Date"
          reference="accounts"
          sortBy="account.trialEnd"
        >
          <DateField source="trialEnd" />
        </ReferenceField>
        <ReferenceField
          source="accountId"
          label="Sensormatic License End Date"
          reference="accounts"
          sortBy="account.sensormaticLicenseEnd"
        >
          <DateField source="sensormaticLicenseEnd" />
        </ReferenceField>
        <DateField source="created" label="Created" />
      </Datagrid>
    </List>
  </React.Fragment>
);

const UserTitle = ({ record }: any) => {
  return (
    <span>
      User{' '}
      {record
        ? `"${record.email} (${record.firstName} ${record.lastName})"`
        : ''}
    </span>
  );
};

const UserEditActions = (props: any) => (
  <TopToolbar>
    <ChangeEmailButton record={props.data} />
    <ResetPasswordButton record={props.data} />
    <StartZohoSyncButton record={props.data} />
  </TopToolbar>
);

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      transform={(data: User) => omitBy(data, isNil)}
      handleSubmitWithRedirect={() => {
        if (!window.confirm('Really update user?')) {
          return false;
        }
        return props.handleSubmitWithRedirect();
      }}
    />
  </Toolbar>
);

export const UserEdit = (props: any) => (
  <Edit
    title={<UserTitle />}
    actions={<UserEditActions />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm
      toolbar={<UserEditToolbar />}
      submitOnEnter={false}
      warnWhenUnsavedChanges
    >
      <h2>User profile</h2>
      <FormRow>
        <TextInput source="id" fullWidth disabled />
        <TextInput source="referrer" fullWidth disabled />
      </FormRow>
      <FormRow>
        <TextInput source="firstName" fullWidth validate={[required()]} />
        <TextInput source="lastName" fullWidth validate={[required()]} />
      </FormRow>
      <FormRow>
        <TextInput source="email" fullWidth disabled />
        <RoleInput source="role" fullWidth disabled />
        <FormDataConsumer>
          {({ formData }: any) => {
            return choices
              .slice(0, 3)
              .find((item) => item.id === formData.jobRole) ||
              !formData.jobRole ? (
              <SelectInput
                source="jobRole"
                label={'Job role'}
                fullWidth
                choices={choices}
              />
            ) : (
              <TextInput source="jobRole" fullWidth validate={[required()]} />
            );
          }}
        </FormDataConsumer>
      </FormRow>
      <FormRow>
        <DateTimeInput
          source="lastLoginRefresh"
          label="Last Active"
          fullWidth
          columnWidth={1}
          disabled
        />
        <DateInput source="created" columnWidth={1} fullWidth disabled />
      </FormRow>

      <h2>Account details</h2>
      <FormRow>
        <TextInput
          source="account.organizationName"
          label="Organization"
          fullWidth
          disabled
        />
        <TextInput source="account.id" fullWidth disabled />
      </FormRow>
      <FormRow>
        <DateInput source="account.createdAt" fullWidth disabled />
        <DateInput source="account.trialEnd" fullWidth disabled />
        <DateInput source="account.sensormaticLicenseEnd" fullWidth disabled />
      </FormRow>
      <FormRow>
        <FunctionField
          render={(record: User) =>
            record.account && (
              <Link to={linkToRecord('/accounts', record.accountId)}>
                Go to account detail
              </Link>
            )
          }
          fullWidth
        />
      </FormRow>
      <br />

      <h2>All users in the same account</h2>
      <ReferenceManyField
        addLabel={false}
        source="accountId"
        reference="users"
        target="accountId"
        fullWidth
      >
        <Datagrid rowClick="edit">
          <TextField source="email" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <RoleField source="role" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'users',
  list: UserList,
  edit: UserEdit,
  icon: UserIcon,
};
