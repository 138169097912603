import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport';

export const exportDate = (date?: string | Date | null) =>
  date ? new Date(date).toLocaleDateString() : '';

export const exportDateTime = (date?: string | Date | null) =>
  date ? new Date(date).toLocaleString() : '';

export const createCsvExporter = <T>(
  name: string,
  rowToExport: (item: T) => Record<string, unknown>
) => (items: T[]) => {
  jsonExport(items.map(rowToExport), (err, csv) => {
    downloadCSV(csv, name);
  });
};
