import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
} from 'react-admin';

import IconVpnKey from '@material-ui/icons/VpnKey';
import IconCancel from '@material-ui/icons/Cancel';
import IconMail from '@material-ui/icons/Mail';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { User } from 'resources/types';

interface Props {
  record: User;
}

const resource = 'reset-password';

export const ResetPasswordButton = ({ record }: Props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate(resource);
  const notify = useNotify();

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  const handleSubmit = async () => {
    create(
      { payload: { data: { id: record?.id } } },
      {
        onSuccess: () => setShowDialog(false),
        onFailure: (error: Error) => notify(error.message, 'error'),
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Reset Password">
        <IconVpnKey />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Reset Password"
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <div>
            An email with password reset link will be sent to the user's current email address.
          </div>
        </DialogContent>
        <DialogActions>
          <SaveButton
            label="Send"
            icon={<IconMail />}
            handleSubmitWithRedirect={handleSubmit}
            disabled={loading}
          />
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
            disabled={loading}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
