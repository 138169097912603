import * as React from 'react';
import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  ArrayField,
  Filter,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  SelectInput,
  FormDataConsumer,


} from 'react-admin';
import Box from '@material-ui/core/Box';
import BookIcon from '@material-ui/icons/Book';
import { DateField } from 'components/DateField';
import { Datagrid } from 'components/Datagrid';
import { Account } from 'resources/types';
import { FormRow } from 'components/FormLayout';
import { RoleField } from 'components/RoleField';
import { StripePriceField } from 'components/StripePriceField';
import { createCsvExporter, exportDate } from 'helpers/exporter';
import { BrandAccessSection } from 'components/BrandAccessSection';


export const AccountIcon = BookIcon;

const rowToExport = (account: Account) => ({
  id: account.id,
  organizationName: account.organizationName,
  companyType: account.companyType,
  customerType: account.customerType,
  ownerEmail: account.owner?.email,
  status: account.status,
  trialEnd: exportDate(account.trialEnd),
  sensormaticLicenseEnd: exportDate(account.sensormaticLicenseEnd),
  licenseEndDate: exportDate(account.licenseEndDate),
  createdAt: exportDate(account.createdAt),
  ownerReferrer: account.owner?.referrer,
  jobRole: account.jobRole,
});

const choices = [
  { id: 'Retailer', name: 'Retailer' },
  { id: 'Broker', name: 'Broker' },
  { id: 'Owner/Developer', name: 'Owner/Developer' },
  { id: 'Other', name: 'Other' },
];

const accountExporter = createCsvExporter('accounts', rowToExport);

const validateAccountCreation = (values: Account) => {
  const errors: any = {};

  if (!values.stripeId && !values?.trialEnd && !values?.sensormaticLicenseEnd) {
    errors.stripeId =
      'You must enter either a trial end date, Stripe ID, Sensormatic license end date or License end date';
    errors.trialEnd =
      'You must enter either a trial end date, Stripe ID, Sensormatic license end date or License end date';
    errors.sensormaticLicenseEnd =
      'You must enter either a trial end date, Stripe ID, Sensormatic license end date or License end date';
    errors.licenseEndDate = 'You must enter either a trial end date, Stripe ID, Sensormatic license end date or License end date';
  }

  return errors;
};

const AccountFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <NumberInput source="id" label="Account ID" />
    <TextInput source="organizationName" label="Organization" />
    <DateInput source="trialEnd_lte" label="Trial End before" />
    <DateInput source="trialEnd_gte" label="Trial End after" />
    <DateInput source="createdAt_lte" label="Created before" />
    <DateInput source="createdAt_gte" label="Created after" />
    <TextInput source="user.referrer" label="Referrer" />
  </Filter>
);

export const AccountList = (props: any) => (
  <List
    filters={<AccountFilter />}
    bulkActionButtons={false}
    {...props}
    exporter={accountExporter}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="organizationName" label="Organization" />
      <TextField source="companyType" label={"Company Type"}/>
      <TextField source="customerType" label={"Customer Type"}/>
      <ReferenceField source="ownerId" reference="users" sortable={false}>
        <TextField source="email" />
      </ReferenceField>
      <TextField source="status" sortable={false} />
      <DateField source="trialEnd" />
      <DateField source="sensormaticLicenseEnd" />
      <DateField source="licenseEndDate" />
      <DateField source="createdAt" />
      <TextField
        source="owner.referrer"
        sortBy="user.referrer"
        label="Referrer"
      />
    </Datagrid>
  </List>
);

const AccountTitle = ({ record }: any) => {
  return <span>Account {record ? `"${record.organizationName}"` : ''}</span>;
};

const AccountEditToolbar = (props: any) => {

  return (

    <Toolbar {...props}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <FormDataConsumer>
        {({ formData }: any) => (
          <SaveButton
            {...props}
            handleSubmitWithRedirect={() => {
              const isChainSame = formData.chain === props.record.chain;
              if (!isChainSame && !window.confirm('Changing the chain linked to this account will erase all dashboards. Do you want to continue?')) {
                return false;
              }
              if (!window.confirm('Really update account?')) {
                return false;
              }
              return props.handleSubmitWithRedirect();
            }}
          />
        )}
        </FormDataConsumer>

        <DeleteButton {...props} undoable={false} />
      </Box>
    </Toolbar>
  );
};

export const AccountEdit = (props: any) => {

  return (
    <Edit title={<AccountTitle />} mutationMode="pessimistic" {...props}>
      <SimpleForm
        toolbar={<AccountEditToolbar />}
        submitOnEnter={false}
        warnWhenUnsavedChanges
      >
        <h2>Account profile</h2>
        <FormRow>
          <TextInput source="organizationName" label="Organization" fullWidth />
          <TextInput source="companyType" label={'Company Type'} fullWidth
                     validate={[required()]}

          />
          <TextInput source="customerType" label={'Customer Type'} validate={[required()]} fullWidth />
          <TextInput source="id" fullWidth disabled />
        </FormRow>
        <FormRow>
          <TextInput
            source="owner.email"
            label="Admin email"
            fullWidth
            disabled
          />
          <TextInput source="status" fullWidth disabled />
        </FormRow>
        <FormDataConsumer>
          {({ formData }: any) => (
            <FormRow>
              <DateInput
                source="trialEnd"
                fullWidth
                disabled={!!formData.sensormaticLicenseEnd || !!formData.licenseEndDate}
              />
              <DateInput
                source="sensormaticLicenseEnd"
                fullWidth
                disabled={!!formData.trialEnd || !!formData.licenseEndDate}
              />
              <DateInput
                source="licenseEndDate"
                fullWidth
                disabled={!!formData.sensormaticLicenseEnd || !!formData.trialEnd}
              />
              <DateInput source="createdAt" fullWidth disabled />
            </FormRow>
          )}
        </FormDataConsumer>

        <h2>Dashboard settings</h2>
        <FormRow>
          <TextInput source="chain" label="Chain ID" fullWidth />
        </FormRow>

        <h2>Billing settings</h2>
        <FormRow>
          <TextInput source="stripeId" label="Stripe ID" fullWidth />
        </FormRow>

        <h2>All users in account</h2>
        <ReferenceManyField
          addLabel={false}
          source="id"
          reference="users"
          target="accountId"
          emptyText="No users found."
          fullWidth
          {...props}
        >
          <Datagrid rowClick="edit">
            <TextField source="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <RoleField source="role" />
          </Datagrid>
        </ReferenceManyField>

        <h2>Billing history</h2>
        <ArrayField addLabel={false} source="invoices" fullWidth>
          <Datagrid>
            <DateField source="created" />
            <TextField source="billingReason" />
            <TextField
              source="subscription.plan.nickname"
              label="Subscription"
            />
            <TextField source="coupon.name" label="Coupon" />
            <StripePriceField source="total" label="Cost" />
            <TextField source="status" />
          </Datagrid>
        </ArrayField>

        <BrandAccessSection />
      </SimpleForm>
    </Edit>
  );
};

export const AccountCreate = (props: any) => {
  return (
    <Create title={<AccountTitle />} {...props}>
      <SimpleForm
        submitOnEnter={false}
        warnWhenUnsavedChanges
        validate={validateAccountCreation}
      >
        <h2>Account &amp; User Info</h2>
        <FormRow>
          <TextInput source="firstName" fullWidth validate={[required()]} />
          <TextInput source="lastName" fullWidth validate={[required()]} />
        </FormRow>
        <FormRow>
          <TextInput source="email" fullWidth validate={[required()]} />
          <TextInput
            source="organizationName"
            fullWidth
            validate={[required()]}
          />
          <TextInput
            source={'companyType'}
            fullWidth
            validate={[required()]}
          />
          <TextInput
            source={'customerType'}
            fullWidth
            validate={[required()]}
          />
        </FormRow>

        <FormRow>
          <TextInput source="referrer" fullWidth initialValue="Almanac 2.0" />

          <FormDataConsumer>
            {({ formData }: any) => {
              return choices
                .slice(0, 3)
                .find((item) => item.id === formData.jobRole) ||
              !formData.jobRole ? (
                <SelectInput
                  source="jobRole"
                  fullWidth
                  validate={[required()]}
                  choices={choices}
                />
              ) : (
                <TextInput source="jobRole" fullWidth validate={[required()]} />
              );
            }}
          </FormDataConsumer>
        </FormRow>

        <h2>Dashboard settings</h2>
        <FormRow>
          <TextInput source="chain" label="Chain ID" fullWidth />
        </FormRow>

        <h2>Billing settings</h2>
        <FormDataConsumer>
          {({ formData }: any) => (
            <FormRow>
              <DateInput
                source="trialEnd"
                fullWidth
                disabled={!!formData.sensormaticLicenseEnd || !!formData.licenseEndDate}
              />
              <DateInput
                source="sensormaticLicenseEnd"
                fullWidth
                disabled={!!formData.trialEnd || !!formData.licenseEndDate}
              />
              <DateInput
                source="licenseEndDate"
                fullWidth
                disabled={!!formData.licenseEndDate || !!formData.trialEnd} />
              <TextInput source="stripeId" label="Stripe ID" fullWidth />
            </FormRow>
          )}
        </FormDataConsumer>

        <BrandAccessSection />
      </SimpleForm>
    </Create>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'accounts',
  list: AccountList,
  edit: AccountEdit,
  create: AccountCreate,
  icon: AccountIcon,
};
