import React from 'react';
import get from 'lodash/get';
import { TextField } from 'react-admin';
import { Role } from './types';

export const RoleField = ({ record, source, ...rest}: any) => {
  const value = get(record, source);
  const displayValue = Role[value] ?? 'UNKNOWN';
  return <TextField {...{...rest, source, record: {...record, [source]: displayValue }}}/>
}
