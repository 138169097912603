import * as React from 'react';
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin';
import { FormRow } from 'components/FormLayout';

export const BrandAccessSection = (props: any) => {
  const [initialValue] = React.useState(props.record?.accessibleBrands || []);
  return (
    <>
      <h2>Almanac 2.0 Access</h2>
      <ArrayInput
        addLabel={false}
        source="accessibleBrands"
        initialValue={initialValue}
        fullWidth
      >
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource, scopedFormData }: any) => {
              return (
                <FormRow>
                  <TextInput
                    source={getSource('pid')}
                    record={scopedFormData}
                    label="ID"
                    fullWidth
                    multiline={!scopedFormData || !scopedFormData.name}
                    rows={5}
                  />
                  <TextInput
                    source={getSource('name')}
                    record={scopedFormData}
                    label="Name"
                    fullWidth
                    disabled
                  />
                </FormRow>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
