import React, { useState } from 'react';
import {
  required,
  email,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
} from 'react-admin';

import IconEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import IconMail from '@material-ui/icons/Mail';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { User } from 'resources/types';

interface Props {
  record: User;
}

interface Values {
  email: string;
}

const resource = 'change-email';

// Built using this tutorial for reference:
// https://marmelab.com/blog/2020/04/27/react-admin-tutorials-custom-forms-related-records.html
export const ChangeEmailButton = ({ record }: Props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate(resource);
  const notify = useNotify();

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  const handleSubmit = async (values: Values) => {
    if (values.email === record?.email) {
      notify('Email must be different', 'error');
      return;
    }
    create(
      { payload: { data: { id: record?.id, email: values.email } } },
      {
        onSuccess: () => setShowDialog(false),
        onFailure: (error: Error) => notify(error.message, 'error'),
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Change Email">
        <IconEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Change Email"
      >
        <DialogTitle>Change Email</DialogTitle>
        <FormWithRedirect
          resource={resource}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }: any) => (
            <>
              <DialogContent>
                <div>
                  Enter the new email address. An email with change link will be
                  sent to the new address.
                </div>
                <TextInput source="email" validate={[required(), email()]} />
              </DialogContent>
              <DialogActions>
                <SaveButton
                  label="Send"
                  icon={<IconMail />}
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
