import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
} from 'react-admin';
import get from 'lodash/get';

import Sync from '@material-ui/icons/Sync';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { User } from 'resources/types';
import { zohoCRMId } from './ZohoCRMIdField';

interface Props {
  record: User;
}

const resource = 'start-zoho-sync';

export const StartZohoSyncButton = ({ record }: Props) => {
  const id = get(record, 'id');
  const canResume = get(record, zohoCRMId) === '';
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate(resource);
  const notify = useNotify();

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);

  const handleSubmit = async () => {
    create(
      { payload: { data: { id } } },
      {
        onSuccess: () => setShowDialog(false),
        onFailure: (error: Error) => notify(error.message, 'error'),
      }
    );
  };

  return canResume
    ? (
      <>
        <Button onClick={handleClick} label="Start Zoho Sync">
          <Sync />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label="Start Zoho Sync"
        >
          <DialogTitle>Start Zoho Sync</DialogTitle>
          <DialogContent>
            <div>
              Corresponding Zoho Contact has been deleted. Do you want to create a new one and start synchronization again?
            </div>
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="ra.action.confirm"
              icon={<Sync />}
              handleSubmitWithRedirect={handleSubmit}
              disabled={loading}
            />
            <Button
              label="ra.action.cancel"
              onClick={handleCloseClick}
              disabled={loading}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
    : null;
};
