import * as React from 'react';
import get from 'lodash/get';
import { DateField as ReactAdminDateField } from 'react-admin';
import { cloneDeep, set } from 'lodash';

export const DateField = (props: any) => {
  if (!props.record) {
    return null;
  }

  const value = get(props.record, props.source);
  if (value === null) {
    return <ReactAdminDateField {...props} />;
  }

  const date = new Date(value);
  if (date.getFullYear() === 3333) {
    // For some reason, API consideres year 3333 as null
    return null;
  } else if (date.getFullYear() === 1970) {
    // We probably got unix time in seconds instead of milliseconds.
    // Kind of hacky, I know, but Admin app gets a mixed types
    // and it's easier to handle it here.
    const clonedRecord = set(
      cloneDeep(props.record),
      props.source,
      new Date(date.getTime() * 1000)
    );

    return <ReactAdminDateField {...props} record={clonedRecord} />;
  }

  return <ReactAdminDateField {...props} />;
};
