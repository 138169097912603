import React from 'react';
import get from 'lodash/get';
import Tooltip from '@material-ui/core/Tooltip';
import Timer from '@material-ui/icons/Timer';
import Sync from '@material-ui/icons/Sync';
import SyncDisabled from '@material-ui/icons/SyncDisabled';

const Waiting = () => <Tooltip title="Waiting for synchronization start"><Timer/></Tooltip>
const Synchronizing = () => <Tooltip title="Synchronizing"><Sync/></Tooltip>
const NotSynchronizing = () => <Tooltip title="Synchronization stopped"><SyncDisabled/></Tooltip>

export const zohoCRMId = 'zohoCRMId'

export const ZohoCRMIdField = ({ record }: any) => {
  const value = get(record, zohoCRMId);
  const DisplayValue = value === null ? Waiting : value === '' ? NotSynchronizing : Synchronizing
  return <DisplayValue />
}
